<template>
  <div class="container">
    <a class="btn__back" @click="$router.go(-1)">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 20C15.5141 20 20 15.5141 20 10C20 4.48586 15.5141 0 10 0C4.48586 0 0 4.48586 0 10C0 15.5141 4.48586 20 10 20ZM6.21121 9.68795L11.4263 4.47283C11.5115 4.38763 11.6228 4.34591 11.734 4.34591C11.8453 4.34591 11.9565 4.38849 12.0417 4.47283C12.2112 4.64232 12.2112 4.91786 12.0417 5.08735L7.13429 9.99479L12.0409 14.9031C12.2104 15.0726 12.2104 15.3481 12.0409 15.5176C11.8714 15.6871 11.5958 15.6871 11.4263 15.5176L6.21121 10.3025C6.04172 10.133 6.04172 9.85744 6.21121 9.68795Z"
          fill="#DB353A"
        />
      </svg>
      <span>Back</span>
    </a>

    <h2 class="leading">Create new password</h2>
    <br />

    <form class="form" @submit.prevent="submitForm" novalidate="true">
      <div class="form__group">
        <div class="form__group--three">
          <label for="password" class="form__label"></label>
          <input
            :type="visiblePassword ? 'text' : 'password'"
            id="password"
            name="password"
            placeholder="Enter new password"
            class="form__input"
            v-model="$v.form.password.$model"
            :class="{ 'is-invalid': submitted && $v.form.password.$error }"
          />
          <div class="visible-password" @click="visiblePassword = !visiblePassword">
            <i v-if="!visiblePassword" class="fas fa-eye-slash"></i>
            <i v-else class="fas fa-eye"></i>
          </div>
          <small class="error" v-if="submitted && !$v.form.password.required"
            >Password is required</small
          >
        </div>

        <div class="check_pass" style="margin-top: -1rem">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.19157 4.13672H7.64196C7.52243 4.13672 7.40876 4.19414 7.33844 4.29258L5.49625 6.84726L4.66187 5.68945C4.59156 5.59219 4.47906 5.53359 4.35835 5.53359H3.80874C3.73257 5.53359 3.68804 5.62031 3.73257 5.68242L5.19273 7.70742C5.22723 7.75557 5.2727 7.7948 5.32538 7.82186C5.37806 7.84892 5.43644 7.86304 5.49566 7.86304C5.55489 7.86304 5.61326 7.84892 5.66594 7.82186C5.71863 7.7948 5.7641 7.75557 5.79859 7.70742L8.26657 4.28555C8.31227 4.22344 8.26774 4.13672 8.19157 4.13672Z"
              fill="#949599"
            />
            <path
              d="M6 0.75C3.10078 0.75 0.75 3.10078 0.75 6C0.75 8.89922 3.10078 11.25 6 11.25C8.89922 11.25 11.25 8.89922 11.25 6C11.25 3.10078 8.89922 0.75 6 0.75ZM6 10.3594C3.59297 10.3594 1.64062 8.40703 1.64062 6C1.64062 3.59297 3.59297 1.64062 6 1.64062C8.40703 1.64062 10.3594 3.59297 10.3594 6C10.3594 8.40703 8.40703 10.3594 6 10.3594Z"
              fill="#949599"
            />
          </svg>
          <p class="sm_text" :style="validateLength ? '' : 'color: #db353a'">
            Minimum of eight (8) characters
          </p>
        </div>

        <div class="check_pass">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.19157 4.13672H7.64196C7.52243 4.13672 7.40876 4.19414 7.33844 4.29258L5.49625 6.84726L4.66187 5.68945C4.59156 5.59219 4.47906 5.53359 4.35835 5.53359H3.80874C3.73257 5.53359 3.68804 5.62031 3.73257 5.68242L5.19273 7.70742C5.22723 7.75557 5.2727 7.7948 5.32538 7.82186C5.37806 7.84892 5.43644 7.86304 5.49566 7.86304C5.55489 7.86304 5.61326 7.84892 5.66594 7.82186C5.71863 7.7948 5.7641 7.75557 5.79859 7.70742L8.26657 4.28555C8.31227 4.22344 8.26774 4.13672 8.19157 4.13672Z"
              fill="#949599"
            />
            <path
              d="M6 0.75C3.10078 0.75 0.75 3.10078 0.75 6C0.75 8.89922 3.10078 11.25 6 11.25C8.89922 11.25 11.25 8.89922 11.25 6C11.25 3.10078 8.89922 0.75 6 0.75ZM6 10.3594C3.59297 10.3594 1.64062 8.40703 1.64062 6C1.64062 3.59297 3.59297 1.64062 6 1.64062C8.40703 1.64062 10.3594 3.59297 10.3594 6C10.3594 8.40703 8.40703 10.3594 6 10.3594Z"
              fill="#949599"
            />
          </svg>
          <p class="sm_text" :style="validateUppercase ? '' : 'color: #db353a'">
            At least one (1) uppercase character
          </p>
        </div>

        <div class="check_pass" style="margin-bottom: 2rem">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.19157 4.13672H7.64196C7.52243 4.13672 7.40876 4.19414 7.33844 4.29258L5.49625 6.84726L4.66187 5.68945C4.59156 5.59219 4.47906 5.53359 4.35835 5.53359H3.80874C3.73257 5.53359 3.68804 5.62031 3.73257 5.68242L5.19273 7.70742C5.22723 7.75557 5.2727 7.7948 5.32538 7.82186C5.37806 7.84892 5.43644 7.86304 5.49566 7.86304C5.55489 7.86304 5.61326 7.84892 5.66594 7.82186C5.71863 7.7948 5.7641 7.75557 5.79859 7.70742L8.26657 4.28555C8.31227 4.22344 8.26774 4.13672 8.19157 4.13672Z"
              fill="#949599"
            />
            <path
              d="M6 0.75C3.10078 0.75 0.75 3.10078 0.75 6C0.75 8.89922 3.10078 11.25 6 11.25C8.89922 11.25 11.25 8.89922 11.25 6C11.25 3.10078 8.89922 0.75 6 0.75ZM6 10.3594C3.59297 10.3594 1.64062 8.40703 1.64062 6C1.64062 3.59297 3.59297 1.64062 6 1.64062C8.40703 1.64062 10.3594 3.59297 10.3594 6C10.3594 8.40703 8.40703 10.3594 6 10.3594Z"
              fill="#949599"
            />
          </svg>
          <p class="sm_text" :style="validateSymbol ? '' : 'color: #db353a'">
            At least one (1) symbol
          </p>
        </div>

        <div class="form__group--three">
          <label for="confirmPassword" class="form__label"></label>
          <input
            :type="visiblePassword2 ? 'text' : 'password'"
            id="confirmPassword"
            name="confirmPassword"
            placeholder="Confirm new password"
            class="form__input"
            v-model="$v.form.confirmPassword.$model"
            :class="{ 'is-invalid': submitted && $v.form.confirmPassword.$error }"
          />
          <div class="visible-password" @click="visiblePassword2 = !visiblePassword2">
            <i v-if="!visiblePassword2" class="fas fa-eye-slash"></i>
            <i v-else class="fas fa-eye"></i>
          </div>
          <small class="error" v-if="submitted && !$v.form.confirmPassword.required"
            >Confirm password is required</small
          >
          <small class="error" v-if="matchPassword"
            >Password and confirm password does not match</small
          >
        </div>
        <br />
      </div>
      <button type="submit" class="btn btn__red btn__group">
        <loading v-if="loading" />
        <span>Proceed</span>
      </button>
    </form>
  </div>
</template>

<script>
import api from "@/api/api.js";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  data() {
    return {
      loading: false,
      submitted: false,
      visiblePassword: false,
      visiblePassword2: false,

      form: {
        password: "",
        confirmPassword: "",
      },
    };
  },
  validations: {
    form: {
      password: { required },
      confirmPassword: { required },
    },
  },
  computed: {
    ...mapState(["forgetPasswordData"]),

    matchPassword() {
      if (
        this.form.confirmPassword !== "" &&
        this.form.password !== this.form.confirmPassword
      ) {
        return true;
      }
      return false;
    },
    validateLength() {
      // more than 8 digits
      if (this.form.password.length >= 8) {
        return true;
      }
      return false;
    },
    validateUppercase() {
      // at least one capital letter
      if (/(.*[A-Z].*)/.test(this.form.password)) {
        return true;
      }
      return false;
    },
    validateSymbol() {
      // at least one special character
      if (/[^A-Za-z0-9]/.test(this.form.password)) {
        return true;
      }
      return false;
    },
  },
  beforeMount() {
    if (this.forgetPasswordData == null) {
      this.$router.push("/password-reset/account");
      return;
    }
  },
  methods: {
    submitForm() {
      this.submitted = true;

      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      if (
        this.matchPassword ||
        !this.validateLength ||
        !this.validateUppercase ||
        !this.validateSymbol
      ) {
        return;
      }

      let payload = {
        userid: this.forgetPasswordData.userId,
        newpassword: this.form.password,
      };

      this.loading = true;

      api
        .forgotPassword(payload)
        .then((response) => {
          this.loading = false;
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            this.$message({
              showClose: true,
              message: `Password Reset Successfully`,
              type: "success",
              duration: 5000,
            });
            setTimeout(() => {
              this.$router.push("/login");
            }, 1000);
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
.sm_text {
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  letter-spacing: 0.05em;
  padding-left: 1rem;
}
.check_pass {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
}
.visible-password {
  float: right;
  margin-top: -35px;
  padding: 0 6px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
</style>
